import ApplicationController from './application_controller'
import Sortable from 'sortablejs'

export default class extends ApplicationController {
  static values = { name: String }
  static targets = [ "drop", "expander" ]

  connect () {
    super.connect()
    this.element[this.identifier] = this;

    const options = {
      group: { name: this.nameValue, revertClone: true },
      removeCloneOnHide: false,
      onChange: this.onChange,
      draggable: '.craziness',
      ghostClass: 'hidden'
    }

    this.sortable = new Sortable(this.dropTarget, options)
  }

  disconnect() {
    super.disconnect()
    this.sortable.destroy()
  }

  onChange = event => {
    console.log(event)
    this.expanderTarget.classList.remove('hidden')
    document.addEventListener('sortable:dragend', this.dragend);
  }

  dragend = event => {
    this.expanderTarget.classList.add('hidden')
    document.removeEventListener('sortable:dragend', this.dragend);
  }
}
